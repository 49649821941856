import { Component, NgModule,EventEmitter, Output } from '@angular/core';
import { sidebarAnimation } from './sidebar-animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '../../theme.service';
import { ImagesService } from '../../services/image/images.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [sidebarAnimation],


})
export class SidebarComponent {
  @Output() menuItemClicked = new EventEmitter<void>();
  isOpened = false;
  nombreEmpresa: string = 'Billcentrix';
  isPreview : Boolean = false;
  param : any;

  constructor(private activateRoute : ActivatedRoute,private router: Router,public themeService: ThemeService, private imageService : ImagesService) {
    this.activateRoute.params.subscribe( params =>{
      // ESTO ES PARA VALIDAR SI ES UNA VISTA PREVIA O SE ESTA CARGANDO UN PORTAL YA CREADO
      this.isPreview = params["id"] != undefined ? true : false;
      if(this.isPreview){
       this.param = params["id"]
      }
   });
  }
  onMenuItemClick() {
    this.menuItemClicked.emit(); // Emitir el evento al hacer clic
  }
  navigateToMisSuscripciones() {
    // this.router.navigate(['/suscripciones']);
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/suscripciones"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/suscripciones"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }
  navigateToDireciones() {
    // this.router.navigate(['/direcciones']);
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/direcciones"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard/direcciones"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }
  navigateToTarjetas() {
    // this.router.navigate(['/password']);
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/tarjetas"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard/tarjetas"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  changePassword() {
    // this.router.navigate(['/password']);
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/password"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard/password"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  goToHome(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  goToDash(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/dashboard-content"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard/dashboard-content"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  openSidenav() {
    this.isOpened = true;
  }

  closeSidenav() {
    this.isOpened = false;
  }
  
  getImageLogo() {
    const imageName = 'logo.svg';
    const imageUrl = `../assets/images/${imageName}`;
    return imageUrl;
  }
  getLogoUrl(){
    return this.imageService.getLogoImage();
  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
}
