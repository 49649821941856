<mat-sidenav-container class="sidenav-container" [ngClass]="themeService.currentPalette">
    <mat-sidenav #sidenav class="sidenav" mode="side" [opened]="sidenavOpened">
        <app-sidebar (menuItemClicked)="closeSidenav()"></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
        <!-- Contenido principal de la página -->
        <mat-toolbar class="toolbar-container" [class.sidebar-opened]="sidenavOpened">
            <button mat-icon-button class="icon" aria-label="Abrir/Cerrar menú" (click)="toggleSidenav()">
                <mat-icon>{{ sidenavOpened ? 'menu_open' : 'menu' }}</mat-icon>
            </button>
            <span class="name_page">Bienvenido</span>
  <div id="accountButton"  (mouseover)="isDropdownOpen = true" (mouseleave)="isDropdownOpen = false">
            <div class="perfil" >
    <img [src]="imagenAleatoria" alt="Foto de perfil" />
    <!-- <p>Hola<br><span>{{ nombreUsuario }}</span></p> -->
    <p>Hola</p>
    <div class="dropdown-options" [ngClass]="{ 'show-options': isDropdownOpen }">
        <a (click)="onAcccount()"><mat-icon>account_circle</mat-icon> Mi Cuenta</a>
        <a (click)="onPassword()"><mat-icon>lock_open</mat-icon> Cambiar contraseña</a>
        <a (click)="logout()"><mat-icon>exit_to_app</mat-icon> Cerrar sesión</a>
      </div>
  </div>
   
  </div>
   
  
            
           
        </mat-toolbar>   
        <div class="content-dashboard">
           
            <router-outlet></router-outlet>

        </div>
    
    </mat-sidenav-content>
</mat-sidenav-container>