import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ThemeService } from '../../theme.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  nombreUsuario: string = 'Nicolas'; // Nombre de usuario
  imagenAleatoria: string; //Imagen Aleatoria
  sidenavOpened = false; // Inicialmente cerrado
  isDropdownOpen: boolean = false;
  tarjetas: boolean = true;
  isPreview : Boolean = false;
  param : any;
  @ViewChild('sidenav') sidenav!: MatSidenav;

  isMobile: boolean = false;
  constructor(public themeService: ThemeService,private activateRoute : ActivatedRoute,private mediaMatcher: MediaMatcher, private router : Router, private notification : NotificationService,private authService: AuthService) {
    this.activateRoute.params.subscribe( params =>{
      // ESTO ES PARA VALIDAR SI ES UNA VISTA PREVIA O SE ESTA CARGANDO UN PORTAL YA CREADO
      this.isPreview = params["id"] != undefined ? true : false;
      if(this.isPreview){
       this.param = params["id"]
      }
   });
    // Selecciona una imagen de perfil aleatoria de una lista de imágenes
    const perfilImagenes: string[] = [
      '../assets/images/profile/men-01.png',
      '../assets/images/profile/men-02.png',
      '../assets/images/profile/men-03.png',
      '../assets/images/profile/men-04.png',
      '../assets/images/profile/girl-02.png',
      '../assets/images/profile/girl-03.png',
      '../assets/images/profile/girl-04.png',
      '../assets/images/profile/girl-05.png',
      // Agrega más rutas de imágenes según sea necesario
    ];
    this.imagenAleatoria = perfilImagenes[Math.floor(Math.random() * perfilImagenes.length)];
  // Verifica si es móvil o no
  const mobileQuery = this.mediaMatcher.matchMedia('(max-width: 720px)');
  this.isMobile = mobileQuery.matches;
// Verifica si es móvil o no y ajusta sidenavOpened en consecuencia
if (this.isMobile) {
  this.sidenavOpened = false; // Cierra el mat-sidenav en dispositivos móviles
} else {
  this.sidenavOpened = true; // Abre el mat-sidenav en pantallas más grandes
}mobileQuery.addEventListener('change', () => {
  this.isMobile = mobileQuery.matches;
  if (this.isMobile) {
    this.sidenavOpened = false; // Cierra el mat-sidenav en dispositivos móviles
  } else {
    this.sidenavOpened = true; // Abre el mat-sidenav en pantallas más grandes
  }
});
  }

  

  toggleSidenav() {
    this.sidenavOpened = !this.sidenavOpened;
    this.sidenav.toggle();
  }
  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
  closeSidenav() {
    this.sidenav.close(); // Cierra el sidenav directamente
    this.sidenavOpened = false; // Sincroniza el estado
  }
  logout(){
    this.authService.closeSesion();
    this.notification.showSuccess("Ha salido de la sesion correctamente");
    // this.router.navigateByUrl("/");
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  onAcccount(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }

  onPassword(){
    if(this.isPreview){
      this.router.navigate(["/preview/" + this.param + "/dashboard/password"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }else{
      this.router.navigate(["/dashboard/password"], {relativeTo : this.activateRoute, queryParamsHandling : "preserve"});
    }
  }
}
