import { Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeService } from '../../theme.service';
import { AddCardFormComponent } from '../add-card-form/add-card-form.component'
import { MatDialog } from '@angular/material/dialog';
import { AddAddressComponent } from '../add-address/add-address.component';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { WebService } from 'src/app/services/web.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})

export class CreditCardComponent {

  tdc: any = [];
  tarjetas: any = [];
  items: Array<any> = [];
  
  themeColors: any; 
  isAddCardFormVisible = false; 
  isDialogOpen = false;

  currentPage = 1; // Página inicial
  itemsPerPage = 5; // Número de direcciones por página
  public totalPages = 0 ;

  constructor(public themeService: ThemeService, private dialog: MatDialog, private auth: AuthService, private web: WebService, private notification: NotificationService, private loader: LoaderService) {
    this.loader.open();
    this.items = [];
    // ESTO ES ESENCIAL PARA LA PAGINACION
    let httpParams = this.web.buildRequestParams(null,"m",
        {pageIndex: +this.currentPage - 1 , pageSize: this.itemsPerPage})
    this.web.get(this.web.HOST + '/creditcard/customer/' + this.auth.getCustomer(), httpParams).subscribe(params => {
      this.totalPages = params.pager.pages;
      if (params['result'] != null && params['result'].length > 0) {
        this.tarjetas = params['result'];
        ///Por cada tarjeta
        this.tarjetas.forEach(tdc =>{
          // Se carga el nombre del proveedor TDC en la columna indicada
          tdc.expMonthCreditCard = tdc.creditCardProvider.description;// this.tarjetas[i].providerCreditCard.description;
          // Se corta el número enmascarado de la TDC de manera de mostrar solo los últimos 8 dígitos
          if(tdc.mask_card != null){
            tdc.maskCreditCard = tdc.mask_card.substr(tdc.mask_card.length - 8);
            if(tdc.corerId != null ){
              this.web.get(this.web.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + tdc.corerId).subscribe(parms => {
                //   this.loader.open();
                // Se guarda la descripción del estado en la columna correspondiente
                tdc.estatus = parms['result']['statusCreditCard'];
                // Se añade la TDC a la tabla correspondiente
                this.tdc.push({ id: tdc.corerId, type: tdc.expMonthCreditCard, number: tdc.maskCreditCard, status: tdc.estatus, token : tdc.token_creditCardToken });
                this.loader.close();
              }, (err) => {
                this.notification.showError(err);
                this.loader.close();
              });
            }else{
              tdc.estatus = 0;
              // Se añade la TDC a la tabla correspondiente
              this.tdc.push({ id: tdc.corerId, type: tdc.expMonthCreditCard, number: tdc.maskCreditCard, status: tdc.estatus, token : tdc.token_creditCardToken });
              this.loader.close();
            }

          }else {
            tdc.maskCreditCard = "XXXXXXXXXX";
            tdc.estatus = 0;
            this.tdc.push({ id: tdc.corerId, type: tdc.expMonthCreditCard, number: tdc.maskCreditCard, status: tdc.estatus, token : tdc.token_creditCardToken });
            this.loader.close();
          }


        });
      } else {
        console.log("NO TDC");
        this.loader.close();
      }
    }, err => {
      this.notification.showError(err);
      this.loader.close();
    });

  }

  find(){
    this.loader.open();
    // ESTO ES ESENCIAL PARA LA PAGINACION
    let httpParams = this.web.buildRequestParams(null,"m",
        {pageIndex: this.currentPage -1 , pageSize: this.itemsPerPage})
    this.web.get(this.web.HOST + '/creditcard/customer/' + this.auth.getCustomer(), httpParams).subscribe(params => {
      this.totalPages = params.pager.pages;
      if (params['result'] != null) {
        this.tarjetas = params['result'];
        ///Por cada tarjeta
        this.tarjetas.forEach(tdc =>{
          // Se carga el nombre del proveedor TDC en la columna indicada
          tdc.expMonthCreditCard = tdc.creditCardProvider.description;// this.tarjetas[i].providerCreditCard.description;
          // Se corta el número enmascarado de la TDC de manera de mostrar solo los últimos 8 dígitos
          if(tdc.mask_card != null){
            tdc.maskCreditCard = tdc.mask_card.substr(tdc.mask_card.length - 8);
            if(tdc.corerId != null ){
              this.web.get(this.web.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + tdc.corerId).subscribe(parms => {
                //   this.loader.open();
                // Se guarda la descripción del estado en la columna correspondiente
                tdc.estatus = parms['result']['statusCreditCard'];
                // Se añade la TDC a la tabla correspondiente
                this.tdc.push({ id: tdc.corerId, type: tdc.expMonthCreditCard, number: tdc.maskCreditCard, status: tdc.estatus, token : tdc.token_creditCardToken });
                this.loader.close();
              }, (err) => {
                this.notification.showError(err);
                this.loader.close();
              });
            }else{
              tdc.estatus = 0;
              // Se añade la TDC a la tabla correspondiente
              this.tdc.push({ id: tdc.corerId, type: tdc.expMonthCreditCard, number: tdc.maskCreditCard, status: tdc.estatus, token : tdc.token_creditCardToken });
              this.loader.close();
            }

          }else {
            tdc.maskCreditCard = "XXXXXXXXXX";
            tdc.estatus = 0;
            this.tdc.push({ id: tdc.corerId, type: tdc.expMonthCreditCard, number: tdc.maskCreditCard, status: tdc.estatus, token : tdc.token_creditCardToken });
            this.loader.close();
          }


        });
      } else {
        console.log("NO TDC");
        this.loader.close();
      }
    }, err => {
      this.notification.showError(err);
      this.loader.close();
    });
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.find();
    }
  }


  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.find();
    }
  }


  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

  toggleAddCardForm() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(AddCardFormComponent, {
        width: '800px', height: '500px'
      });
      dialogRef.afterClosed().subscribe(() => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
        window.location.reload();
      });
    }
  }

  changeStatus(tdc: any) {
    this.loader.open();

    if (!tdc.id) {
      this.loader.close();
      return this.notification.showInfo("No se puede cambiar el estatus de la tarjeta");
    }

    const action = tdc.status.status === 1 ? 'delete' : 'activate';
    const url = `${this.web.HOST}/customer/${this.auth.getCustomer()}/creditcard/${tdc.id}/${action}`;
    const json = { token: tdc.status.token };

    this.web.post(json, url).subscribe(
        () => {
          this.loader.close();
          this.notification.showSuccess("Actualizada correctamente");
          window.location.reload();
        },
        () => {
          this.loader.close();
          this.notification.showError("No se pudo cambiar el estado de la tarjeta, verifique que no se encuentre asociada a un plan activo");
        }
    );
  }
}
