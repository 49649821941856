import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { WebService } from 'src/app/services/web.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoaderService } from 'src/app/services/loader.service';
import { forkJoin } from 'rxjs';

export interface Tarjeta {
  nombre: string;
  tarjetas: number;
  acciones: string;
}

@Component({
  selector: 'app-mistarjetas',
  templateUrl: './mistarjetas.component.html',
  styleUrls: ['./mistarjetas.component.scss']
})
export class MistarjetasComponent implements OnInit {
  empty = false;
  tarjetas: any[] = [];
  items: Array<any> = [];
  displayedColumns: string[] = ['nameHolderCreditCard', 'expMonthCreditCard', 'actions'];
  dataSource = new MatTableDataSource<Tarjeta>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MistarjetasComponent>,
    private auth: AuthService,
    private web: WebService,
    private notification: NotificationService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    this.loader.open();
    this.loadCreditCards();
  }

  loadCreditCards() {
    this.web.get(this.web.HOST + '/creditcard/customer/' + this.auth.getCustomer()).subscribe(
        (params) => {
          if (params['result'] && params['result'].length > 0) {
            this.tarjetas = params['result'];
            this.tarjetas.forEach(tdc => {
              if(tdc.corerId != null ){
                this.web.get(this.web.HOST + '/customer/' + this.auth.getCustomer() + '/customerHasCreditCard/' + tdc.corerId).subscribe(response => {
                  console.log(response['result']);
                  tdc.estatus = response['result']['statusCreditCard'];
                  tdc.token = response['result']['tokenCreditCard'];
                  tdc.mask_card = tdc.mask_card.substr(tdc.mask_card.length - 8);
                  if(tdc.estatus == 1){
                    this.items.push({ expMonthCreditCard : tdc.expiration_month, expYearCreditCard : tdc.expiration_year , id: tdc.corerId, type: tdc.expMonthCreditCard, maskCreditCard: tdc.mask_card, estatus: tdc.estatus, token : tdc.token, nameHolderCreditCard : tdc.customer.name_customer });
                  }
                  this.dataSource.data = this.items;
                  this.dataSource.paginator = this.paginator;
                  this.loader.close();
                }, (err) => {
                  this.notification.showError(err);
                  this.loader.close();
                });
              }else{
                tdc.estatus = 0;
                this.items.push({ month : tdc.expiration_month, year : tdc.expiration_year , id: tdc.corerId, type: tdc.expMonthCreditCard, maskCreditCard: tdc.mask_card, estatus: tdc.estatus, token : tdc.token, nameHolderCreditCard : tdc.customer.name_customer });
                this.loader.close();
              }
            })
          } else {
            this.loader.close();
          }
        },
        (err) => {
          console.log(err);
          this.notification.showError(err);
          this.loader.close();
        }
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  select(row: any) {
    this.dialogRef.close(row);
  }
}
